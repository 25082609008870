import { Injectable } from '@angular/core';
import { Web3Service, StorageService, MetaMaskService } from 'ng-blockchainx';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import Web3 from 'web3'
const web3 = new Web3(window['ethereum'])
// import Web3 from 'web3/dist/web3.min.js';

@Injectable({
  providedIn: 'root',
})

export class Web3AuthService {
  public account: any;
  public accountObservable: Observable<any>;
  public chainId: string = '';
  private contractAddress: any;
  private web3Service: Web3Service;
  public storage!: StorageService;
  public web3: any;
  public ethWeb3: any;
  private walletAddress: any;
  public walletAddressObserve: any;
  public userNativeBalance: any;
  public userNativeBalanceObservable!: Observable<boolean>;
  public isMetamaskConnected: boolean = false;
  public metamaskConnected: any;
  public isMetamaskConnectedObservable!: Observable<boolean>;
  public currentNetworkName: any = '';
  public currentNetworkSymbol: any = '';
  public currentNativeCurrency: any = '';
  public currentExplorer: any = ';';
  public globalObservable: any
  public accountDetailsObservable: any
  private connectMetaMask: any;
  public connectObservable!: Observable<any>;

  constructor(
    private _storage: StorageService,
    private _web3Service: Web3Service,
    private metaMaskService: MetaMaskService
  ) {

    this.connectMetaMask = new BehaviorSubject(false);
    this.connectObservable = this.connectMetaMask.asObservable();

    this.metamaskConnected = new BehaviorSubject(false);
    this.isMetamaskConnectedObservable = this.metamaskConnected.asObservable();

    this.userNativeBalance = new BehaviorSubject('');
    this.userNativeBalanceObservable = this.userNativeBalance.asObservable();

    if (environment.production == true) {
      this._storage.config(true);
    }

    this.walletAddress = new BehaviorSubject('');
    this.walletAddressObserve = this.walletAddress.asObservable();
    this.storage = this._storage;
    this.web3Service = this._web3Service;
    this.account = this.storage.get('account');

    (this.chainId = this.account ? this.account.chainId : environment.SUPPORTED_CHAINS),
      '0x13881',
      '0xa869';
    this.getChainId((chainId: string) => {
      this.chainId = chainId;
    });

    this.account = new BehaviorSubject('');
    this.accountObservable = this.account.asObservable();

    this.web3 = this.web3Service.web3;
    this.ethWeb3 = this.web3Service.ethWeb3;
  }

  async init() {
    if (this.storage.get('walletType') != 2 || this.storage.get('walletType') == null) {
      const rpcUrl = environment[this.chainId].PROVIDER;
      this.web3Service.connect({
        chainId: this.chainId,
        rpcUrl: rpcUrl,
        infuraApiKey: '49709bb81fa74b15a224e25af168c1ce',
      });
      this.web3 = this.web3Service.web3;
      this.ethWeb3 = this.web3Service.ethWeb3;
    }
    else {
      const rpcUrl = environment[this.chainId].PROVIDER;
      const chainIdInDec = this.web3.utils.hexToNumberString(this.chainId);
      this.web3Service.connect({
        chainId: chainIdInDec,
        rpcUrl: rpcUrl,
      });
      this.web3 = await this.web3Service.web3;
      this.ethWeb3 = await this.web3Service.web3;
    }
  }

  /**
   * enable metamask connection
   * @param{boolean} data
   */
  public enableMetaMaskConnection(data: boolean) {
    this.connectMetaMask.next(data);
  }

  public async getChainId(cb: any) {
    const chainId = await this.metaMaskService.getChainId();
    cb(chainId);
  }
  public get Web3Service() {
    return this.web3Service;
  }

  /**
   * Gets contract params
   * @param contractType
   * @returns
   */
  public async getContractParams(contractType: string) {
    // @ts-ignore
    return {
      contractAddress: this.contractAddress,
      abi: require('../../../contracts/' +
        this.chainId +
        '/' +
        contractType +
        '.json'),
    };
  }

  /**
   * Gets abi
   * @param contractType
   * @returns
   */
  // public async getAbi(contractType: string) {
  //   return require('src/contracts/' +
  //     this.chainId +
  //     '/' +
  //     contractType +
  //     '.json');
  // }

  /**
   * Gets contract address
   * @param contractType
   * @returns
   */
  public async getContractAddress() {
    return this.contractAddress;
  }

  /**
   * Gets contract
   * @param contractType
   * @returns
   */
  // public async getContract(contractType: string) {
  //   const params = await this.getContractParams(contractType);
  //   return await this.web3Service.getContract(
  //     params.abi,
  //     params.contractAddress
  //   );
  // }

  /**
   * Gets contract by address
   * @param contractType
   * @param contractAddress
   * @returns
   */
  public async getContractByAddress(
    contractType: string,
    contractAddress: string
  ) {
    const params = await this.getContractParams(contractType);
    return await new web3.eth.Contract(params.abi, contractAddress, {from : this.account.walletAddress}) 
  }
  
  /**
   * get wallet adddress
   */
  public sendWalletAddress(address: string) {
    this.walletAddress.next(address);
  }
  public async getNativeCurrencyBalance(account: any) {
    let bnbBalanceInWallet = await this.ethWeb3.eth.getBalance(account);
    return (bnbBalanceInWallet = await this.ethWeb3.utils.fromWei(
      bnbBalanceInWallet
    ));
  }

  public async setAccount(account: any) {
    await this.account.next(account);
    let balance = await this.getNativeCurrencyBalance(account.walletAddress);
    this.userNativeBalance.next(parseFloat(balance).toFixed(4));
  }

  /**
   * Determines whether valid address is
   * @param address
   * @returns
   */
  public async isValidAddress(address: string) {
    try {
      let response = await this.ethWeb3.eth.getCode(address);
      let status;
      if (response == '0x') status = true;
      else status = false;
      return status;
    } catch (err) {
      return false;
    }
  }

  /**
   * Determines whether erc20 standard is
   * @param address
   * @returns
   */
  // public async isERC20Standard(address: string) {
  //   try {
  //     let tokenContract = await this.getContractByAddress(
  //       'ERC20_TOKEN_CONTRACT',
  //       address
  //     );
  //     let name = await tokenContract.methods.name().call();
  //     let symbol = await tokenContract.methods.symbol().call();
  //     let decimal = await tokenContract.methods.decimals().call();
  //     let totalSupply = await tokenContract.methods.totalSupply().call();
  //     console.log(name, symbol, decimal, totalSupply);
  //     if (name && symbol && decimal && totalSupply) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } catch (err: any) {
  //     console.log('error', err);
  //     return false;
  //   }
  // }

  //   /**
  //  * Currents network details
  //  * @param networkDetailsIndex
  //  */
    public async currentNetworkDetails(networkDetailsIndex: any) {
      if (networkDetailsIndex >= 0) {
        this.isMetamaskConnected = true;
        this.metamaskStatus(this.isMetamaskConnected);
      }
    }

  /**
   * Decimals divider
   * @param [divider]
   * @param [value]
   * @returns
   */
  public decimalDivider(divider: number = 1, value: number = 0) {
    // @ts-ignore
    const web3 = new Web3(window['ethereum']);
    let originalValue;
    try {
      switch (Number(divider)) {
        case 18: {
          let weiValue = web3.utils.fromWei(value.toString(), 'ether');
          originalValue = weiValue;
          break;
        }

        case 9: {
          let weiValue = web3.utils.fromWei(value.toString(), 'gwei');
          originalValue = weiValue;
          break;
        }

        case 6: {
          let weiValue = web3.utils.fromWei(value.toString(), 'mwei');
          originalValue = weiValue;
          break;
        }

        default: {
          originalValue = Number(value) / 10 ** (18 - divider);
          break;
        }
      }
      return originalValue;
    } catch (e) {
      console.log('conversion exception..', e);
    } finally {
      return originalValue;
    }
  }
  /**
   * @param{boolean}status
   */

  metamaskStatus(status: boolean) {
    this.metamaskConnected.next(status);
  }

    /**
   * To check sum address
   * @param address
   * @returns
   */
    public async toCheckSumAddress(address: string) {
      return await Web3.utils.toChecksumAddress(address);
    }

  /**
   * function to check whether an address is a valid Ethereum wallet address
   * @param address
   * @returns true if valid ethaddress
   */
  public isValidETHAddress(address: string): boolean {
    return Web3.utils.isAddress(address);
  }
}
