// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    FILE_PATH: '',
    API_BASE_URL: 'https://aoc-backend.seaswap.co/api/v1/admin',
    SUPPORTED_CHAINS: ['0x38', '0x61'],
    BSC_TRANSACTION_HASH_BASEURL: 'https://bscscan.com/tx/',
  TRON_TRANSACTION_HASH_BASEURL: 'https://tronscan.org/#/transaction/',
  ETH_TRANSACTION_HASH_BASEURL: 'https://etherscan.io/tx/',
    '0x61': {
      EXPLORER: 'https://testnet.bscscan.com/',
      PROVIDER: 'https://data-seed-prebsc-2-s3.binance.org:8545',
      CHAIN_NETWORK: '0x61',
      CHAINID: 97,
      CURRENCY_NAME: 'BNB',
      CURRENCY_SYMBOL: 'BNB',
      NETWORK_SYMBOL: 'BSC',
      CHAIN_NAME: 'BSC TESTNET',
      CROWD_SALE: "0xab6FafcA96b9F1384413FD0008169461A8Da24d4",
    },
    '0x38': {
      EXPLORER: 'https://bscscan.com/',
      PROVIDER: 'https://go.getblock.io/319082e3389e49b4a42dc828013e01cb',
      CHAIN_NETWORK: '0x38',
      CHAINID: 56,
      CURRENCY_NAME: 'BNB',
      CURRENCY_SYMBOL: 'BNB',
      NETWORK_SYMBOL: 'BSC',
      CHAIN_NAME: 'BSC MAINNET',
      CROWD_SALE: "0x9645a07Bfee003061bcf8090529ba72c44D42058",
    },
    FILE_UPLOAD_URL : 'https://aoc-backend.seaswap.co/api/v1/file-upload/upload'
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  