import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogComponent } from './popupService/dialog/dialog.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FuseConfirmationModule } from '@fuse/services/confirmation';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
// import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatNativeDateModule } from '@angular/material/core';
// import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
// import { HttpLoaderFactory } from '../app.module';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PaginationComponent } from './pagination/pagination.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatSnackBarModule,
    FuseConfirmationModule,
    MatTableExporterModule,
    MatSlideToggleModule,
    MatTabsModule,
    // MatButtonToggleModule,
    // NgxDropzoneModule,
    MatNativeDateModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient],
    //   },
    // }),
    MatCardModule,
    // MatListModule,
    MatDialogModule,
    MatSelectModule,
    // MatExpansionModule,
    MatIconModule,
    MatButtonModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    FuseConfirmationModule,
    MatTableExporterModule,
    MatSlideToggleModule,
    MatTabsModule,
    // MatButtonToggleModule,
    // NgxDropzoneModule,
    MatNativeDateModule,
    MatIconModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: HttpLoaderFactory,
    //     deps: [HttpClient],
    //   },
    // }),
    MatCardModule,
    // MatListModule,
    MatDialogModule,
    MatSelectModule,
    PaginationComponent
    
    // MatExpansionModule,
    ],
    declarations: [
      DialogComponent,
      PaginationComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule
{
}
 