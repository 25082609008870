import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef, Inject, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { StorageService, MetaMaskService } from 'ng-blockchainx';
import { Web3AuthService } from 'app/core/auth/web3-auth.service';
import { environment } from 'environments/environment';
import { SnackbarService } from 'app/shared/services/snackbar-service.service';
import { AuthService } from 'app/core/auth/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface connectionResponse {
    status: boolean,
    data: any,
    chainId: string
}

@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None
})

export class ClassyLayoutComponent implements OnInit, OnDestroy {
    account: any;
    accountObservable: any;
    connectObservable: any;
    currentActiveNetwork: any;
    currentWalletConnected: any;
    chainId: any;
    isMetamaskConnected: any;
    isScreenSmall: boolean;
    navigation: Navigation;
    user: User;
    countdown: number = 5;
    countdownMapping: any = {
        '=1': '# second',
        'other': '# seconds'
    };
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private storageService: StorageService,
        private metaMaskService: MetaMaskService,
        private web3AuthService: Web3AuthService,
        private snackbarService: SnackbarService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit() {

        if (this.storageService.get('isAdmin') == 0) {
            await localStorage.clear()
            this.authService._authenticated = false
            await this._router.navigateByUrl('sign-in')
            await console.clear()
            return;
        }

        this.account = this.storageService.get('account');
        console.log('this.account.chainId', this.account.chainId);
        
        if (this.account.chainId != '') {
            this.currentWalletConnected = this.account.walletAddress;
            console.log('this.currentActiveNetwork', environment[this.account.chainId].CHAIN_NAME);
            this.currentActiveNetwork = environment[this.account.chainId].CHAIN_NAME;
            
            
        }
        this.isMetamaskConnected = this.storageService.get('isWalletConnected')
        console.log(this.currentWalletConnected, this.currentActiveNetwork, this.isMetamaskConnected);

        if (this.account != null && this.isMetamaskConnected) {
            this.walletConnection();
        }

        this.connectObservable =
            this.web3AuthService.isMetamaskConnectedObservable.subscribe(
                (response: boolean) => {
                    this.isMetamaskConnected = response
                }
            );

        this.accountObservable =
            this.web3AuthService.accountObservable.subscribe(
                async (res: string) => {
                    if (res) {
                        this.account = await res;
                        this.storageService.set('account', {
                            walletAddress: this.account.walletAddress,
                            chainId: this.account.chainId,
                        });

                        this.storageService.set('connection', {
                            status: true,
                            data: [this.account.walletAddress],
                            chainId: this.account.chainId,
                        });

                        this.currentWalletConnected = this.account.walletAddress;
                        this.cdr.detectChanges();
                    }
                }
            );

        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    async walletConnection() {
        this.metaMaskService.connectionListener.subscribe(async (response: any) => {
            if (response.code == 250601) {
                if (environment.SUPPORTED_CHAINS.includes(response.data.chainId))
                    this.connectionHandler(response.data)
                else {
                    this.authService._authenticated = false
                    this.snackbarService.error("Wrong network detected", 2000)
                    await localStorage.clear()
                    await this._router.navigateByUrl('sign-in')
                }
            }

            // metamask not installed
            if (response.code == 250511) {
                this._router.navigateByUrl('sign-in')
                this.authService._authenticated = false
            }

            //Metamask not connected	
            if (response.code == 250512) {
                this.authService._authenticated = false
                localStorage.clear()
                this._router.navigateByUrl('sign-in')
            }

            //account changed
            if (response.code == 250611) {
                this.authService.checkAdmin({ wallet_address: response.data.accounts[0] }).subscribe(() => {
                    this.storageService.set('isAdmin', 1)
                    this.snackbarService.success("Account switch successful", 1000)
                    this.setNetworkDetails(response)
                },
                    () => {
                        const dialogConfig = new MatDialogConfig();
                        dialogConfig.disableClose = true; // Prevent closing when clicking outside or refreshing
                        dialogConfig.autoFocus = true; // Set focus on the dialog
                        const dialogRef = this.dialog.open(DialogComponent2, dialogConfig);

                        this.storageService.set('isAdmin', 0)
                        dialogRef.afterClosed().subscribe(() => {
                            if (!this.storageService.get('isAdmin')) {
                                localStorage.clear()
                                location.reload()
                            }
                        });
                        dialogRef.close();
                    })
            }

            // chain changed
            if (response.code == 250610) {
                if (!environment.SUPPORTED_CHAINS.includes(response.data.chainId)) {
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.disableClose = true; // Prevent closing when clicking outside or refreshing
                    dialogConfig.autoFocus = true; // Set focus on the dialog
                    this.dialog.open(DialogComponent, dialogConfig);
                } else
                    this.setNetworkDetails(response);
            }
        });
    }

    connectionHandler(account: any) {
        this.chainId = account.chainId;
        this.currentWalletConnected = account.account[0];
        this.storageService.set('account', {
            walletAddress: account.account[0],
            chainId: account.chainId,
        });
        this.isMetamaskConnected = true;
        this.storageService.set('isWalletConnected', this.isMetamaskConnected);
    }

    async setNetworkDetails(response: any,) {
        if (response.code == 250611) {
            this.chainId = this.account.chainId
            this.currentWalletConnected = response.data.accounts[0];
        }
        else {
            this.chainId = response.data.chainId
            this.currentWalletConnected = this.account.walletAddress
        }

        let networkDetailsIndex = environment.SUPPORTED_CHAINS.indexOf(
            this.chainId
        );

        if (networkDetailsIndex >= 0) {
            await this.web3AuthService.currentNetworkDetails(
                networkDetailsIndex
            );
            this.currentActiveNetwork = environment[this.chainId].CHAIN_NAME
            this.web3AuthService.setAccount({
                walletAddress: this.currentWalletConnected,
                chainId: this.chainId
            });
            this.isMetamaskConnected = true;
            this.storageService.set('isWalletConnected', this.isMetamaskConnected);
        }
        // else {
        // await this.snackbarService.error("Wrong Network Detected", 2500)
        // await localStorage.clear()
        // setTimeout(() => {
        //     window.location.reload()
        // }, 500);
        // }
    }

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}

@Component({
    selector: 'app-dialog',
    styleUrls: ['./dialog.component.scss'],
    template: `
    <ng-container>
    <h1 mat-dialog-title>Wrong network detected</h1>
    <div mat-dialog-content>
    Switch network to proceed
    </div>
    <button mat-button (click)="switchNetwork(this.chainId)">Switch network</button> 
    </ng-container>
    `,
})
export class DialogComponent {
    public chainId = environment.SUPPORTED_CHAINS;
    constructor(
        public dialogRef: MatDialogRef<DialogComponent>,
        private ngZone: NgZone,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    async switchNetwork(chainId) {
        this.ngZone.run(async () => {
            try {
                let ethereum = window['ethereum']
                const { CHAIN_NETWORK, CHAIN_NAME, CURRENCY_NAME, CURRENCY_SYMBOL, PROVIDER, EXPLORER } = environment[chainId]
                await ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{
                        chainId: CHAIN_NETWORK,
                        chainName: CHAIN_NAME,
                        nativeCurrency: {
                            name: CURRENCY_NAME,
                            symbol: CURRENCY_SYMBOL,
                            decimals: 18,
                        },
                        rpcUrls: [PROVIDER],
                        blockExplorerUrls: [EXPLORER],
                    }],
                })
                this.dialogRef.close()
                location.reload()
            }
            catch (err) {
                this.dialogRef.close()
                await localStorage.clear()
                setTimeout(() => {
                    location.reload()
                }, 500);
            }

        })
    }
}

@Component({
    selector: 'app-dialog2',
    styleUrls: ['./dialog.component.scss'],
    template: `
    <ng-container>
    <h1 mat-dialog-title>Wrong account detected</h1>
    <div mat-dialog-content>
    The account connected is not seems to admin's account. Switch account to proceed.
    </div>
    </ng-container>
    `,
})
export class DialogComponent2 {
    constructor(
        public dialogRef: MatDialogRef<DialogComponent2>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }
}