import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog,  MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  // templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  template: `
<div class="mx-auto max-w-md">
  <div class="bg-white rounded-lg shadow-lg p-8">
    <h1 class="text-3xl font-bold mb-6 text-shadow">Action</h1>
    <div class="mb-6 text-center">
      <p></p>
    </div>
    <!-- <button  (click)="deleteUser()" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded w-full">
      Delete User
    </button> -->
    <button  (click)="blockUser()" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded w-full">
      Block User
    </button>
  </div>
</div>
  `,
})
export class DialogComponent {
  constructor(private dialog: MatDialog,
    // public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) { }

  // closeDialog(): void {
  //     this.dialogRef.close();
  // }

  deleteUser() {

  }

  blockUser() {

  }
}
