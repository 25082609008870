import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() totalItems: number = 0; // Total items for pagination
  @Input() pageSize: number = 10; // Items per page

  @Output() pageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  @ViewChild(MatPaginator) paginator!: MatPaginator; // Use non-null assertion operator

  constructor() { }


  ngOnInit(): void {
    console.log("pagination");
  }

  // onPageChange(event: PageEvent): void {
  //   this.pageChange.emit(event);
  // }

  // @Input() length = 0; // Total number of items
  // @Input() pageSize = 10; // Default page size
  // @Input() pageSizeOptions: number[] = [5, 10, 25, 100]; // Default page size options

  // @Output() pageChange = new EventEmitter<any>(); // Emit event for page change

  // constructor() {}

  // ngOnInit(): void {}

  onPageChange(event: any) {
    this.pageChange.emit(event); // Emit page change event to parent component
  }


  @Input() totalDocs: number = 0; // Total number of documents
  @Input() limit: number = 10; // Number of items per page
  @Input() currentPage: number = 1; // Current page index

//  @Output() pageChange = new EventEmitter<any>(); // Event emitter for page change

  // onPageChange(event: any) {
  //   const pageIndex = event.pageIndex + 1; // API expects page starting from 1, MatPaginator uses 0
  //   const pageSize = event.pageSize;
  //   this.pageChange.emit({ pageIndex, pageSize }); // Emit the page index and size
  // }
}
