/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'user',
        title: 'Contributors Management',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/contributors-management'
    },
    // {
    //     id   : 'user',
    //     title: 'User Whitelisting',
    //     type : 'basic',
    //     icon : 'heroicons_outline:chart-pie',
    //     link : '/user-whitelisting'
    // },
    {
        id   : 'user',
        title: 'Round Management',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/round'
    },
    {
        id   : 'reports',
        title: 'Reports',
        type : 'collapsable',
        icon : 'heroicons_outline:chart-pie',
        link : '/report',
        children: [
            {
                id: 'report.type',
                title: 'Contribution and AOC Balance',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/reports/report-1',
            },
            {
                id: 'report.type',
                title: 'Referral AOC bonus',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/reports/report-2',
            },
            {
                id: 'report.type',
                title: 'Referral USDT bonus',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/reports/report-3',
            },
            {
                id: 'report.type',
                title: 'Recap USDT-AOC bonus',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/reports/report-4',
            },
            {
                id: 'report.type',
                title: 'Recap AOC bonus',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/reports/report-5',
            },
            {
                id: 'report.type',
                title: 'Recap total AOC to receive',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/reports/report-6',
            },
            {
                id: 'report.type',
                title: 'Minor Contributors',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/reports/report-7',
            },
            {
                id: 'report.type',
                title: 'Junior Contributors',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/reports/report-8',
            },
            {
                id: 'report.type',
                title: 'Senior Contributors',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/reports/report-9',
            },
            {
                id: 'report.type',
                title: 'Super Senior Contributors',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/reports/report-10',
            },
            {
                id: 'report.type',
                title: 'Contributors Statistic Record',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/reports/report-11',
            },
            // {
            //     id: 'report.type',
            //     title: 'Contributors Statistic Record',
            //     type: 'basic',
            //     icon: 'heroicons_outline:menu-alt-1',
            //     link: '/reports/report-11',
            // },
            {
                id: 'report.type',
                title: 'Contributors Global Data',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/reports/report-12',
            },
            {
                id: 'report.type',
                title: 'USDT bonus CLAIMED/PAID',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/reports/report-13',
            },
        ]
    },
    {
        id   : 'leaderboard',
        title: 'Leaderboard Management',
        type : 'collapsable',
        icon : 'heroicons_outline:chart-pie',
        link : '',
        children: [
            {
                id: 'report.type',
                title: 'Top Sponsers',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/top-sponsers',
            },
            {
                id: 'report.type',
                title: 'Top Country',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/top-country',
            },
        ]
    },
    {
        id   : 'user',
        title: 'Total USDT BEP20',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/total-usdt-data'
    },
    {
        id   : 'withdraw',
        title: 'TOTAL USDT BEP20 Direct Bonus Withdrawn',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/usdt-withdraw-list'
    },
    {
        id   : 'announcement',
        title: 'Announcement Management',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/announcement-management'
    },
    {
        id   : 'whitelist',
        title: 'Whitelist Management',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/whitelist-management'
    },
    {
        id   : 'influencer',
        title: 'Crypto-Martkets and Crypto-Influencers',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/crypto-influencers'
    },
    {
        id   : 'collaborative contribution',
        title: 'Collaborative Contribution Management',
        type : 'collapsable',
        icon : 'heroicons_outline:chart-pie',
        link : '/collaborative-contribution',
        children: [
            {
                id: 'report.type',
                title: 'May 18, 2024 - Aug 28, 2024',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/collaborative-contribution/may18Aug28',
            },
            {
                id: 'report.type',
                title: 'Aug 29, 2024 - Jan 25, 2025',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/collaborative-contribution/aug29Jan25',
            },            
            {
                id: 'report.type',
                title: 'Jan 26, 2025 – Feb 20, 2025',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/collaborative-contribution/jan26Feb20',
            },
            {
                id: 'report.type',
                title: 'Feb 21, 2025 – Mar 05, 2025',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/collaborative-contribution/feb21Mar5',
            },
            {
                id: 'report.type',
                title: 'After Mar 05, 2025',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/collaborative-contribution/afterMar5',
            }
        ]
    },
    {
        id   : 'deleted collaboratives',
        title: 'Deleted Collaborative Contributions',
        type : 'collapsable',
        icon : 'heroicons_outline:chart-pie',
        link : '/deleted-collaboratives',
        children: [
            {
                id: 'report.type',
                title: 'May 18, 2024 - Aug 28, 2024',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/deleted-collaboratives/may18Aug28',
            },
            {
                id: 'report.type',
                title: 'Aug 29, 2024 - Jan 25, 2025',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/deleted-collaboratives/aug29Jan25',
            },
            {
                id: 'report.type',
                title: 'Jan 26, 2025 – Feb 20, 2025',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/deleted-collaboratives/jan26Feb20',
            },
            {
                id: 'report.type',
                title: 'Feb 21, 2025 – Mar 05, 2025',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/deleted-collaboratives/feb21Mar5',
            },
            {
                id: 'report.type',
                title: 'After Mar 05, 2025',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: '/deleted-collaboratives/afterMar5',
            }
        ]
    },
    {
        id   : 'miracle matrix ',
        title: 'Miracle Matrix Management',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/miracle-matrix'
    },
    {
        id   : 'thanks giving contributions',
        title: 'Flash Operation',
        type : 'collapsable',
        icon : 'heroicons_outline:chart-pie',
        link : '',
        children: [
            {
                id: 'report.type',
                title: 'Jan 26, 2025 – Feb 20, 2025',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: 'flash-operation-matrix-users/jan26Feb20',
            },
            {
                id: 'report.type',
                title: 'Feb 21, 2025 – Mar 05, 2025',
                type: 'basic',
                icon: 'heroicons_outline:menu-alt-1',
                link: 'flash-operation-matrix-users/feb21Mar5',
            },
        ]
    },
    {
        id   : 'banner management',
        title: 'Banner Management',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/banner-management'
    },
    {
        id   : 'visitor management',
        title: 'Visitor Management',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/visitor-management'
    },
    {
        id   : 'audio announcement',
        title: 'Audio Announcement',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/audio-announcement'
    },
   

];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
