import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { environment } from 'environments/environment';
import { UserService } from 'app/core/user/user.service';
import { StorageService } from 'ng-blockchainx';

interface LoginResponse {
    data: {
        session: {
            session_token: string;
        }
    }
}

@Injectable()
export class AuthService {
    public _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private storageService: StorageService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: any) {
        this.storageService.set('accessToken', token);
    }

    get accessToken(): any {
        return  this.storageService.get('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    signIn(walletAddress: any, connection: any, isMetaMaskConnected: any): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.get(`${environment.API_BASE_URL}/check-admin/${walletAddress.wallet_address}`).pipe(
            switchMap((response: any) => {                
                if (response.status_code == 200) {

                    this._httpClient.post(`${environment.API_BASE_URL}/login`, walletAddress).subscribe((res: LoginResponse) => {
                        this.accessToken = res.data.session.session_token;
                        this.storageService.set('connection', connection);
                        this.storageService.set('account', { walletAddress: connection.data[0], chainId: connection.chainId });
                        this.storageService.set('isWalletConnected', isMetaMaskConnected);
                    })
                }
                // Set the authenticated flag to true
                this._authenticated = true;

                // // Store the user on the user service
                // this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            }), catchError((err) => {
                return throwError(err.error.message)
            })
        )
    }

    checkAdmin(walletAddress: any): Observable<any> {
        return this._httpClient.get(`${environment.API_BASE_URL}/check-admin/${walletAddress.wallet_address}`).pipe(
            switchMap((response: any) => {
                if (response.status_code == 200) {
                    this._httpClient.post(`${environment.API_BASE_URL}/login`, walletAddress).subscribe((res: LoginResponse) => {                        
                        this.accessToken = res.data.session.session_token;
                    })
                }
                return of(response);
            }), catchError((err) => {
                return throwError(err.error.message)
            })
        )

    }

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    // signIn(credentials: { email: string; password: string }): Observable<any>
    // {
    //     // Throw error, if the user is already logged in
    //     if ( this._authenticated )
    //     {
    //         return throwError('User is already logged in.');
    //     }

    //     return this._httpClient.post('api/auth/sign-in', credentials).pipe(
    //         switchMap((response: any) => {

    //             // Store the access token in the local storage
    //             this.accessToken = response.accessToken;

    //             // Set the authenticated flag to true
    //             this._authenticated = true;

    //             // Store the user on the user service
    //             this._userService.user = response.user;

    //             // Return a new observable with the response
    //             return of(response);
    //         })
    //     );
    // }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Renew token
        return this._httpClient.post('api/auth/refresh-access-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError((err) =>

                // Return false
                of(err)
            ),
            switchMap((response: any) => {
                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        this.storageService.remove('accessToken');
        this.storageService.remove('connection');
        this.storageService.remove('account');
        this.storageService.remove('isWalletConnected');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        // if ( this._authenticated )
        // {            
        //     return of(true);
        // }

        // Check the access token availability
        if (this.accessToken && this.accessToken != undefined && this.accessToken != '' && this.storageService.get('isWalletConnected') == true && this.storageService.get('connection')  && this.storageService.get('account')) {
            this._authenticated = true;
            return of(true);
        }
        else {
            return of(false);
        }

        // Check the access token expire date
        // if ( AuthUtils.isTokenExpired(this.accessToken) )
        // {
        //     return of(false);
        // }

        // If the access token exists and it didn't expire, sign in using it
        // return this.signInUsingToken();
    }
}
